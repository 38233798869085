@import ../node_modules/bulma/bulma

// === THEME ===
:root
  --bg: #272B32
  --text: #111

body
  font-family: "Inter", sans-serif
  font-size: 12pt
  background-color: var(--bg)
  color: var(--text)
  transition: background-color 0.3s, color 0.3s

// === TYPOGRAPHY ===
h1,h2,h3,h4,h5,h6
  font-weight: bold

h1
  font-size: 1.8em
h2
  font-size: 1.6em
h3
  font-size: 1.4em
h4
  font-size: 1.3em
h5
  font-size: 1.2em
h6
  font-size: 1.1em

h1,h2,h3,h4,h5,h6,p
  margin: 0.33em 0 0 0

li
  margin: 0.5em 0

ul, ol
  list-style-position: inside

ol
  list-style-type: decimal

ul
  list-style-type: disc

a
  text-decoration: underline

// === BRANDING ===
.has-branding-background
  background-color: var(--bg)

.has-text-muted
  color: #676B72

.has-text-muted strong
  color: #878B92
  font-weight: 500

footer
  font-size: 0.95rem

footer a
  text-decoration: none

footer a:hover
  text-decoration: none

// === LOGO STYLING ===
.image
  max-width: 450px
  margin: 0 auto

.image img
  width: 100%
  height: auto
  display: block

.fade-in
  opacity: 0
  transform: perspective(600px) translateZ(-120px) rotateX(12deg)
  animation: enter3D 1.8s cubic-bezier(0.25, 1, 0.5, 1) forwards

@keyframes enter3D
  to
    opacity: 1
    transform: perspective(800px) translateZ(0) rotateX(0deg)

.floating-contact-button
  position: fixed
  bottom: 1.5rem
  right: 1.5rem
  z-index: 9999
  background-color: #1f1f1f
  color: #f8f8f8
  border: 1px solid rgba(255, 255, 255, 0.15)
  padding: 0.6em 1.2em
  font-size: 0.9rem
  border-radius: 8px
  text-decoration: none
  font-weight: 500
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3)
  transition: all 0.2s ease-in-out
  backdrop-filter: blur(4px)

  &:hover
    background-color: rgba(255, 255, 255, 0.1)
    color: white
    transform: scale(1.04)
